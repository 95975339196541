import React, { useEffect, useRef, useState } from "react";
import {
  colStyleLeft,
  completeHeaderStyle,
  containerStyle,
  dropdownSectionStyle,
  headerStyle,
  titleSectionStyle,
} from "../../Components/Dashboard";
import LetterAvatar from "../../Components/LetterAvatar";
import TeacherMap from "./components/TeacherMap";
import { getResponseGet } from "../../lib/utils";
import Activity from "./components/Activity";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const TeacherNavigation = ({
  setIsLoggedIn,
  courseId,
  setCourseId,
  topicData,
  setTopicData,
  setEnrolledPolylines,
  colStyleRight,
}) => {
  const navigate = useNavigate();
  const activitiesState = useState([]);

  const [showSummary, setShowSummmary] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const learnerId = localStorage.getItem("id");
  var resetMap = [false];
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  // const [enrolledPolylines, setEnrolledPolylines] = useState([]);
  const [enrolledLearner, setEnrolledLearner] = useState({});
  const [course, setCourse] = useState({});
  const [enrolledLearnersByCourse, setEnrolledLearnersByCourse] = useState({});
  const [moduleData, setModuleData] = useState([]);
  // const [topicData, setTopicData] = useState([]);
  const enrollId = enrolledLearner?.enroll_id;
  const enrollPolyline = enrolledLearner?.polyline;
  const [open, setOpen] = useState(false);
  const svgRef = useRef(null);
  const zoomRef = useRef(null);
  const [showPoly, setShowPoly] = useState(false);
  const [summary, setSummary] = useState("");
  const coordinate = [
    Number(enrolledLearner.x_coordinate || 0),
    Number(enrolledLearner.y_coordinate || 0),
  ];

  const learnerPosState = useState([]);
  const [prevPos, setPrevPos] = useState(null);

  const [openPoly, setOpenPoly] = useState(false);
  const [learnersRefresh, setLearnersRefresh] = useState(false);

  const handleClickOpen = () => {
    setOpenPoly(true);
  };

  const handleClose = () => {
    setOpenPoly(false);
  };

  const handleCourseClick = (id) => {
    setCourseId(id);
    window.location.reload();
  };

  console.log("Position of enrolled learner:", coordinate);
  console.log("Learner position state:", learnerPosState);
  const loadModuleData = async (courseId) => {
    const response = await getResponseGet(`/moduleData/${courseId}`);
    if (response) {
      setModuleData(response.data);
      // console.log("this is the module data", response.data);
    }
  };
  const loadTopicData = async (courseId) => {
    const response = await getResponseGet(`/topics/${courseId}`);
    if (response) {
      setTopicData(response.data);
      console.log("this is the topics data", response.data);
    }
  };
  // const loadEnrollData = async (learnerId) => {
  //   const response = await getResponseGet(
  //     `enrolledLearner/${learnerId}/${courseId}`
  //   ); // add course id afterwards
  //   if (response?.data) {
  //     setEnrolledLearner(response.data);
  //     // console.log("Enrolled Learner", enrolledLearner);
  //     // Update learner position state with the new coordinates if they exist
  //     if (response.data.x_coordinate && response.data.y_coordinate) {
  //       learnerPosState[1]([
  //         Number(response.data.x_coordinate),
  //         Number(response.data.y_coordinate),
  //       ]);
  //     }
  //   } else {
  //     console.error("Failed to fetch enrolled learner data", response);
  //   }
  // };
  const loadEnrollersBycourse = async (courseId) => {
    const response = await getResponseGet(
      `enrolledLearnersByCourse/${courseId}`
    ); // add course id afterwards
    if (response?.data) {
      setEnrolledLearnersByCourse(response.data);
      console.log("All Enrolled Learners", response.data);
    } else {
      console.error("Failed to fetch enrolled learners", response);
    }
  };
  const loadEnrolledCourses = async (learnerId) => {
    const response = await getResponseGet(`enrolledCourses/${learnerId}`);
    if (response?.data) {
      // console.log("Enrolled courses", response.data);
      setEnrolledCourses(response.data);
    } else {
      console.error("Failed to fetch enrolled courses", response);
    }
  };
  const loadEnrolledPolylines = async (enrollId) => {
    const response = await getResponseGet(`enrolledPolylines/${enrollId}`);
    if (response?.data) {
      // console.log("Enrolled Polylines", response.data);
      setEnrolledPolylines(response.data);
    } else {
      console.error("Failed to fetch enrolled polylines", response);
    }
  };
  const loadCourse = async (courseId) => {
    const response = await getResponseGet(`course/${courseId}`);
    if (response?.data) {
      // console.log("course is", response.data);
      setCourse(response.data);
    } else {
      console.error("Failed to fetch the course", response);
    }
  };

  const loadActivityData = async (enrollId) => {
    const response = await getResponseGet(`activities/${enrollId}`);
    if (response?.data) {
      // console.log("Loaded Activities", response.data);
      activitiesState[1](response.data);
    } else {
      console.error("Failed to fetch activities data", response);
    }
  };
  useEffect(() => {
    loadModuleData(courseId);
    loadTopicData(courseId);
    loadEnrollersBycourse(courseId);
    loadCourse(courseId);
  }, [courseId]);

  useEffect(() => {
    if (learnerId) {
      loadEnrolledCourses(learnerId);
      // loadEnrollData(learnerId);
    }
  }, [learnerId]);

  // const enrollId=enrolledLearner.enroll_id;
  // console.log("enrollid is ", enrollId);
  // console.log("plyline is ", enrollPolyline);
  // console.log("polyline is ",enrolledLearner?.polyline);
  useEffect(() => {
    if (enrollId) {
      // loadActivityData(enrollId);
      loadEnrolledPolylines(enrollId);
    }
  }, [enrollId]);

  useEffect(() => {
    if (learnersRefresh) {
      loadEnrollersBycourse(courseId);
      setLearnersRefresh(false);
    }
  }, [learnersRefresh]);

  const handleSummaryClose = (event) => {
    setShowSummmary(false);
    setOpen(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function handleSummaryClick(event) {
    setShowSummmary((curr) => !curr);
    setTarget(event.target);
  }
  return (
    <>
      <div style={containerStyle}>
        <div style={completeHeaderStyle}>
          <div className="header" style={headerStyle}>
            <Button onClick={() => {
							navigate("/t/dashboard");
						}}>
							<i class="fa fa-chevron-left"></i>
						</Button>
            
            <div style={titleSectionStyle}>
              <h1>{course.name} </h1>
            </div>

            <div style={{flexGrow: 1}} />

            <div>
              <LetterAvatar setIsLoggedIn={setIsLoggedIn} />
              <h6>{localStorage.getItem("name")}</h6>
            </div>
          </div>
          <span style={{ fontSize: "12px" }} id="description" className="">
            {course.description}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          {/* ... */}
          <Button onClick={() => {
              sessionStorage.setItem('course_id', courseId); // Set courseId in sessionStorage
              navigate("/create-quiz"); // Navigate to /create-quiz
          }}>
              Add new quiz
          </Button>

        </div>
        <div style={{ display: "flex" }}>
          <div id="learner-map" style={colStyleLeft}>
            {/* Learner map */}
            <TeacherMap
              activitiesState={activitiesState}
              learnerPosState={learnerPosState}
              svgRef={svgRef}
              zoomRef={zoomRef}
              enrollId={enrollId}
              // enrolledLearner={enrolledLearner}
              enrolledLearnersByCourse={enrolledLearnersByCourse}
              courseId={courseId}
            />
          </div>
          <div id="learning-journey" style={colStyleRight}>
            <Activity
              activitiesState={activitiesState}
              enrolledLearnersByCourse={enrolledLearnersByCourse}
              setLearnersRefresh={setLearnersRefresh}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherNavigation;
