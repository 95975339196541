import React, { useEffect } from "react";
import * as d3 from "d3";

const JourneyMap = ({
  tooltipRef,
  transform,
  xScale,
  yScale,
  activitiesState,
}) => {
  const tempActivitiesState = activitiesState[0];

  const activitiesCoordinates = tempActivitiesState.map((activity) => ({
    id: activity.id,
    x: activity.x,
    y: activity.y,
    roundedX: activity.x.toFixed(3),
    roundedY: activity.y.toFixed(3),
  }));

  const newActivitiesState = [
    { x: 0.1, y: 0.1 }, // Starting point
    ...tempActivitiesState.sort((a, b) => a.id - b.id), // Ensure sorted order
  ];

  const inverseScale = Math.min(1 / transform.k, 1.1);

  const lineGenerator = (pointA, pointB) => {
    const x1 = xScale(pointA.x);
    const y1 = yScale(pointA.y);
    const x2 = xScale(pointB.x);
    const y2 = yScale(pointB.y);

    const dx = x2 - x1;
    const dy = y2 - y1;
    const dr = Math.sqrt(dx * dx + dy * dy);
    const offset = 50 * inverseScale;

    const cx = (x1 + x2) / 2 + (dy / dr) * offset;
    const cy = (y1 + y2) / 2 - (dx / dr) * offset;

    return `M ${x1} ${y1} Q ${cx} ${cy} ${x2} ${y2}`;
  };

  useEffect(() => {
    newActivitiesState.forEach((point, index) => {
      d3.select(`#rect-${index}`)
        .attr("width", 30 * inverseScale)
        .attr("height", 30 * inverseScale)
        .attr("stroke-width", 2 * inverseScale);

      d3.select(`#icon-${index}`).attr("font-size", `${20 * inverseScale}px`);

      if (index > 0) {
        d3.select(`#path-${index - 1}`).attr("stroke-width", 2 * inverseScale);
      }
    });
  }, [inverseScale, newActivitiesState, xScale, yScale]);

  useEffect(() => {
    const durationOfTransition = 500;
    const delayTime = 10;
    newActivitiesState.forEach((point, index) => {
      d3.select(`#rect-${index}`)
        .transition()
        .delay(index * delayTime)
        .duration(durationOfTransition)
        .style("opacity", 1);

      d3.select(`#icon-${index}`)
        .transition()
        .delay(index * delayTime)
        .duration(durationOfTransition)
        .style("opacity", 1);

      if (index > 0) {
        d3.select(`#path-${index - 1}`)
          .transition()
          .delay(index * delayTime)
          .duration(durationOfTransition)
          .style("opacity", 1);
      }
    });
  }, [newActivitiesState]);

  return (
    <g>
      {newActivitiesState.map((point, index) => (
        <g key={index}>
          {index === 0 && (
            <circle
              id="icon-circle-end"
              cx={xScale(point.x)}
              cy={yScale(point.y)}
              r={10 * inverseScale}
              fill="#ffbaba"
              stroke="black"
              strokeWidth={2 * inverseScale}
              style={{ opacity: 1 }}
            />
          )}
        </g>
      ))}
      {newActivitiesState.slice(0, -1).map((point, index) => {
        const nextPoint = newActivitiesState[index + 1];
        const pathData = lineGenerator(point, nextPoint);

        return (
          <path
            key={`path-${index}`}
            id={`path-${index}`}
            d={pathData}
            stroke="black"
            strokeWidth={2 * inverseScale}
            strokeDasharray="4,4"
            fill="none"
            markerEnd="url(#arrow)"
            style={{ opacity: 0 }}
          />
        );
      })}
      <defs>
        <marker
          id="arrow"
          viewBox="0 0 10 10"
          refX="10"
          refY="5"
          markerWidth="6"
          markerHeight="6"
          orient="auto-start-reverse"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill="black" />
        </marker>
      </defs>
    </g>
  );
};

export default JourneyMap;

// import React, { useEffect } from "react";
// import * as d3 from "d3";

// const JourneyMap = ({
//   tooltipRef,
//   transform,
//   xScale,
//   yScale,
//   activitiesState,
// }) => {
//   const tempActivitiesState = activitiesState[0];

//   // Create activities coordinates with original and rounded values
//   const activitiesCoordinates = tempActivitiesState.map((activity) => {
//     return {
//       id: activity.id,
//       x: activity.x, // Original precision for calculations
//       y: activity.y, // Original precision for calculations
//       roundedX: activity.x.toFixed(3), // Rounded for rendering
//       roundedY: activity.y.toFixed(3), // Rounded for rendering
//     };
//   });

//   // New activities state with original precision
//   const newActivitiesState = [
//     { x: 0.1, y: 0.1 }, // Original for calculations
//     ...tempActivitiesState.sort((a, b) => a.id - b.id),
//   ];

//   const inverseScale = Math.min(1 / transform.k, 1.1);

//   const lineGenerator = (pointA, pointB) => {
//     const x1 = xScale(pointA.x);
//     const y1 = yScale(pointA.y);
//     const x2 = xScale(pointB.x);
//     const y2 = yScale(pointB.y);

//     const dx = x2 - x1;
//     const dy = y2 - y1;
//     const dr = Math.sqrt(dx * dx + dy * dy);
//     const offset = 50 * inverseScale;

//     const cx = (x1 + x2) / 2 + (dy / dr) * offset;
//     const cy = (y1 + y2) / 2 - (dx / dr) * offset;

//     return `M ${x1} ${y1} Q ${cx} ${cy} ${x2} ${y2}`;
//   };

//   const handleMouseOver = (event, point) => {
//     const tooltip = d3.select(tooltipRef.current);
//     tooltip
//       .style("visibility", "visible")
//       .html(
//         `
//         <div>
//           <strong>Position:</strong> ${point.x.toFixed(3)}, ${point.y.toFixed(
//           3
//         )}
//         </div>
//         `
//       )
//       .style("display", "block")
//       .style("left", `${xScale(point.x)}px`)
//       .style("top", `${yScale(point.y)}px`);
//   };

//   const handleMouseMove = (event, point) => {
//     const tooltip = d3.select(tooltipRef.current);
//     tooltip
//       .style("left", `${xScale(point.x)}px`)
//       .style("top", `${yScale(point.y)}px`);
//   };

//   const handleMouseOut = () => {
//     const tooltip = d3.select(tooltipRef.current);
//     tooltip.style("visibility", "hidden");
//   };

//   // Handle scaling changes without transitions
//   useEffect(() => {
//     newActivitiesState.forEach((point, index) => {
//       // Update the rectangles and icons instantly when zoom level changes
//       d3.select(`#rect-${index}`)
//         .attr("width", 30 * inverseScale)
//         .attr("height", 30 * inverseScale)
//         .attr("stroke-width", 2 * inverseScale);

//       d3.select(`#icon-${index}`)
//         .attr("x", xScale(point.x) + 15 * inverseScale)
//         .attr("y", yScale(point.y) + 15 * inverseScale)
//         .attr("font-size", `${20 * inverseScale}px`);

//       if (index > 0) {
//         d3.select(`#path-${index - 1}`).attr("stroke-width", 2 * inverseScale);
//       }
//     });
//   }, [inverseScale, newActivitiesState, xScale, yScale]);

//   const durationOfTransition = 500;
//   useEffect(() => {
//     newActivitiesState.forEach((point, index) => {
//       d3.select(`#rect-${index}`)
//         .transition()
//         .delay(index * 1000)
//         .duration(durationOfTransition)
//         .style("opacity", 1);

//       d3.select(`#icon-${index}`)
//         .transition()
//         .delay(index * 1000)
//         .duration(durationOfTransition)
//         .style("opacity", 1);

//       if (index > 0) {
//         d3.select(`#path-${index - 1}`)
//           .transition()
//           .delay(index * 1000)
//           .duration(durationOfTransition)
//           .style("opacity", 1);
//       }
//     });
//   }, [newActivitiesState]);

//   return (
//     <g>
//       {newActivitiesState.map((point, index) => (
//         <g key={index}>
//           {index === 0 || index === newActivitiesState.length - 1 ? (
//             <circle
//               id={`icon-circle-${index}`}
//               cx={xScale(point.x)}
//               cy={yScale(point.y)}
//               r={10 * inverseScale} // Adjust radius as needed
//               fill="#ffbaba"
//               stroke="black"
//               strokeWidth={2 * inverseScale}
//               style={{ opacity: 1 }}
//               onMouseOver={(event) => handleMouseOver(event, point)}
//               onMouseMove={(event) => handleMouseMove(event, point)}
//               onMouseOut={handleMouseOut}
//             />
//           ) : null}
//           <text
//             id={`icon-${index}`}
//             x={xScale(point.x)}
//             y={yScale(point.y)}
//             fill="black"
//             fontSize={`${20 * inverseScale}px`}
//             fontFamily="FontAwesome"
//             textAnchor="middle"
//             dominantBaseline="middle"
//             style={{ opacity: 1 }}
//             onMouseOver={(event) => handleMouseOver(event, point)}
//             onMouseMove={(event) => handleMouseMove(event, point)}
//             onMouseOut={handleMouseOut}
//           >
//             {index === 0 || index === newActivitiesState.length - 1
//               ? "\uf183"
//               : ""}
//           </text>
//         </g>
//       ))}
//       {newActivitiesState.slice(0, -1).map((point, index) => {
//         const nextPoint = newActivitiesState[index + 1];
//         const pathData = lineGenerator(point, nextPoint);

//         return (
//           <path
//             key={`path-${index}`}
//             id={`path-${index}`}
//             d={pathData}
//             stroke="black"
//             strokeWidth={2 * inverseScale}
//             strokeDasharray="4,4"
//             fill="none"
//             markerEnd="url(#arrow)"
//             style={{ opacity: 0 }}
//           />
//         );
//       })}
//       <defs>
//         <marker
//           id="arrow"
//           viewBox="0 0 10 10"
//           refX="10"
//           refY="5"
//           markerWidth="6"
//           markerHeight="6"
//           orient="auto-start-reverse"
//         >
//           <path d="M 0 0 L 10 5 L 0 10 z" fill="black" />
//         </marker>
//       </defs>
//     </g>
//   );
// };

// export default JourneyMap;

// import React, { useEffect } from "react";
// import * as d3 from "d3";

// const JourneyMap = ({
//   tooltipRef,
//   transform,
//   xScale,
//   yScale,
//   activitiesState,
// }) => {
//   //   const tempActivitiesState = activitiesState[0];
//   //   const activitiesCoordinates = activitiesState[0].map((activity) => {
//   //     return { id: activity.id, x: activity.x, y: activity.y };
//   //   });
//   //   console.log("activitiesCoordinates : ", activitiesCoordinates);
//   //   const newActivitiesState = [
//   //     { x: 0.1, y: 0.1 },
//   //     ...tempActivitiesState.sort((a, b) => a.id - b.id),
//   //   ];
//   //   console.log("newActivitiesState : ", newActivitiesState);

//   const tempActivitiesState = activitiesState[0];

//   const activitiesCoordinates = tempActivitiesState.map((activity) => {
//     return {
//       id: activity.id,
//       x: activity.x.toFixed(3), // Render with 3 decimals
//       y: activity.y.toFixed(3), // Render with 3 decimals
//       originalX: activity.x, // Keep original precision
//       originalY: activity.y, // Keep original precision
//     };
//   });

//   console.log("activitiesCoordinates : ", activitiesCoordinates);

//   const newActivitiesState = [
//     { x: (0.1).toFixed(3), y: (0.1).toFixed(3) }, // Render with 3 decimals
//     ...tempActivitiesState
//       .map((activity) => ({
//         ...activity,
//         roundedX: activity.x.toFixed(3), // Add rounded value for rendering
//         roundedY: activity.y.toFixed(3), // Add rounded value for rendering
//       }))
//       .sort((a, b) => a.id - b.id),
//   ];

//   console.log("newActivitiesState : ", newActivitiesState);

//   const handleMouseOver = (event, point) => {
//     const tooltip = d3.select(tooltipRef.current);
//     tooltip
//       .style("visibility", "visible")
//       .html(
//         `
//         <div>
//           <strong>Position:</strong> ${point.x.toFixed(3)}, ${point.y.toFixed(
//           3
//         )}
//         </div>
//         `
//       )
//       .style("display", "block")
//       .style("left", `${xScale(point.x)}px`)
//       .style("top", `${yScale(point.y)}px`);
//   };

//   const handleMouseMove = (event, point) => {
//     const tooltip = d3.select(tooltipRef.current);
//     tooltip
//       .style("left", `${xScale(point.x)}px`)
//       .style("top", `${yScale(point.y)}px`);
//   };

//   const handleMouseOut = () => {
//     const tooltip = d3.select(tooltipRef.current);
//     tooltip.style("visibility", "hidden");
//   };

//   const inverseScale = Math.min(1 / transform.k, 1.1);

//   // Handle only scaling changes without transitions
//   useEffect(() => {
//     newActivitiesState.forEach((point, index) => {
//       // Update the rectangles and icons instantly when zoom level changes
//       d3.select(`#rect-${index}`)
//         .attr("width", 30 * inverseScale)
//         .attr("height", 30 * inverseScale)
//         .attr("stroke-width", 2 * inverseScale);

//       d3.select(`#icon-${index}`)
//         .attr("x", xScale(point.x) + 15 * inverseScale)
//         .attr("y", yScale(point.y) + 15 * inverseScale)
//         .attr("font-size", `${20 * inverseScale}px`);

//       if (index > 0) {
//         d3.select(`#path-${index - 1}`).attr("stroke-width", 2 * inverseScale);
//       }
//     });
//   }, [inverseScale, newActivitiesState, xScale, yScale]);
//   const durationOfTransition = 500;
//   useEffect(() => {
//     newActivitiesState.forEach((point, index) => {
//       d3.select(`#rect-${index}`)
//         .transition()
//         .delay(index * 1000)
//         .duration(durationOfTransition)
//         .style("opacity", 1);

//       d3.select(`#icon-${index}`)
//         .transition()
//         .delay(index * 1000)
//         .duration(durationOfTransition)
//         .style("opacity", 1);

//       if (index > 0) {
//         d3.select(`#path-${index - 1}`)
//           .transition()
//           .delay(index * 1000)
//           .duration(durationOfTransition)
//           .style("opacity", 1);
//       }
//     });
//   }, [newActivitiesState]);

//   const lineGenerator = (pointA, pointB) => {
//     const x1 = xScale(pointA.x) + 15 * inverseScale;
//     const y1 = yScale(pointA.y) + 15 * inverseScale;
//     const x2 = xScale(pointB.x) + 15 * inverseScale;
//     const y2 = yScale(pointB.y) + 15 * inverseScale;

//     const dx = x2 - x1;
//     const dy = y2 - y1;
//     const dr = Math.sqrt(dx * dx + dy * dy);
//     const offset = 50 * inverseScale;

//     const cx = (x1 + x2) / 2 + (dy / dr) * offset;
//     const cy = (y1 + y2) / 2 - (dx / dr) * offset;

//     return `M ${x1} ${y1} Q ${cx} ${cy} ${x2} ${y2}`;
//   };

//   return (
//     <g>
//       {newActivitiesState.map((point, index) => (
//         <g key={index}>
//           {index === 0 || index === newActivitiesState.length - 1 ? (
//             <circle
//               id={`icon-circle-${index}`}
//               //   cx={xScale(point.x) + 15 * inverseScale}
//               //   cy={yScale(point.y) + 15 * inverseScale}
//               cx={xScale(point.x) + 15 * inverseScale}
//               cy={yScale(point.y) + 15 * inverseScale}
//               r={10 * inverseScale} // Adjust radius as needed
//               fill="#ffbaba"
//               stroke="black"
//               strokeWidth={2 * inverseScale}
//               style={{ opacity: 1 }}
//               onMouseOver={(event) => handleMouseOver(event, point)}
//               onMouseMove={(event) => handleMouseMove(event, point)}
//               onMouseOut={handleMouseOut}
//             />
//           ) : null}
//           <text
//             id={`icon-${index}`}
//             x={xScale(point.x) + 15 * inverseScale}
//             y={yScale(point.y) + 15 * inverseScale}
//             fill="black"
//             fontSize={`${20 * inverseScale}px`}
//             fontFamily="FontAwesome"
//             textAnchor="middle"
//             dominantBaseline="middle"
//             style={{ opacity: 1 }}
//             onMouseOver={(event) => handleMouseOver(event, point)}
//             onMouseMove={(event) => handleMouseMove(event, point)}
//             onMouseOut={handleMouseOut}
//           >
//             {index === 0 || index === newActivitiesState.length - 1
//               ? "\uf183"
//               : ""}
//           </text>
//         </g>
//       ))}
//       {newActivitiesState.slice(0, -1).map((point, index) => {
//         const nextPoint = newActivitiesState[index + 1];
//         const pathData = lineGenerator(point, nextPoint);

//         return (
//           <path
//             key={`path-${index}`}
//             id={`path-${index}`}
//             d={pathData}
//             stroke="black"
//             strokeWidth={2 * inverseScale}
//             strokeDasharray="4,4"
//             fill="none"
//             markerEnd="url(#arrow)"
//             style={{ opacity: 0 }}
//           />
//         );
//       })}
//       <defs>
//         <marker
//           id="arrow"
//           viewBox="0 0 10 10"
//           refX="10"
//           refY="5"
//           markerWidth="6"
//           markerHeight="6"
//           orient="auto-start-reverse"
//         >
//           <path d="M 0 0 L 10 5 L 0 10 z" fill="black" />
//         </marker>
//       </defs>
//     </g>
//   );
// };

// export default JourneyMap;
