import React from 'react';
import LetterAvatar from "../../../Components/LetterAvatar";

const UserInfo = ({ setIsLoggedIn }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', padding: '16px', width: '100%', background: "#ccc" }}>
      <div style={{ padding: "8px", flexGrow: 1 }}>
        <h1>Hello, {localStorage.getItem("name")}</h1>
        <h6>Learn Courses better on Navigated Learning</h6>
      </div>
      <div>
        <LetterAvatar setIsLoggedIn={setIsLoggedIn} />
        <h6>{localStorage.getItem("name")}</h6>
      </div>
    </div>
  )
}

export default UserInfo